import React, { PureComponent } from 'react';

type Props = {
  children: React.ReactNode;
};

type State = {
  error: false | { message: string };
  errorTime?: number;
};

export default class ErrorBounds extends PureComponent<Props, State> {
  state: State = { error: false };

  static getDerivedStateFromError(error: any) {
    return { error, errorTime: Date.now() };
  }

  static getDerivedStateFromProps(nextProps: Props, { errorTime }: State) {
    // If error is older than 3sec, try to unset error
    if (Date.now() - (errorTime ?? 0) > 3000) {
      return {
        error: false,
      };
    }

    return false;
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <div className="row v-center h-center __err">
          Error: <b>{error.message}</b>
        </div>
      );
    }
    return this.props.children;
  }
}
