import React, { forwardRef } from 'react';
import { IShape, IconProps } from 'vev';
import { useIcon } from '../../core/hooks';

const DEFAULT_SHAPE: IShape = [100, 100, ['M50 0 L100 100  L0 100 Z']];

const Icon = forwardRef(({ stretch, d, ...rest }: IconProps, ref) => {
  const shape = useIcon(d || 'default');

  if (!shape) {
    return null;
  }

  if (typeof shape === 'string') {
    return <img data-icon src={shape} ref={ref as any} {...rest} />;
  }

  if ('url' in shape) {
    return <img data-icon src={shape.url} ref={ref as any} {...rest} />;
  }

  if (Array.isArray(shape)) {
    const [width, height, ...paths]: IShape = shape || DEFAULT_SHAPE;
    return (
      <svg
        ref={ref as any}
        data-icon
        {...rest}
        preserveAspectRatio={stretch ? 'none' : 'xMidYMid'}
        viewBox={`0 0 ${width} ${height}`}
      >
        <>
          {typeof paths === 'string' ? (
            <path d={paths} />
          ) : (
            paths.map((path, index) => {
              return <path key={index} d={path as string} />;
            })
          )}
        </>
      </svg>
    );
  }

  return <img data-icon src={shape.value.url} ref={ref as any} {...rest} />;
});

Icon.displayName = 'Icon';

export default Icon;
