import system from '../system';

export default function polyfills(): Promise<any> {
  const polyfills: Promise<any>[] = [];
  // Polyfill for fetch
  if (!('fetch' in window)) {
    polyfills.push(
      system.fetch('https://cdn.jsdelivr.net/npm/whatwg-fetch@3.0.0/dist/fetch.umd.js'),
    );
  }
  // Polyfill for intersection observer
  if (!('IntersectionObserver' in window)) {
    polyfills.push(
      system.import('https://unpkg.com/intersection-observer@0.5.1/intersection-observer'),
    );
  }
  return Promise.all(polyfills);
}
