/* tslint:disable:no-bitwise */
import {
  SWIPE,
  DIRECTION_NONE,
  DIRECTION_LEFT,
  DIRECTION_RIGHT,
  DIRECTION_UP,
  DIRECTION_DOWN,
} from './config';
import { MultiFingerStatus, Finger } from 'vev';

function _calcTriangleDistance(x: number, y: number): number {
  return Math.sqrt(x * x + y * y);
}

function _calcAngle(x: number, y: number): number {
  const radian = Math.atan2(y, x);
  return 180 / (Math.PI / radian);
}

export function calcMultiFingerStatus(touches: any): MultiFingerStatus | undefined {
  if (touches && touches.length >= 2) {
    const { x: x1, y: y1 } = touches[0];
    const { x: x2, y: y2 } = touches[1];
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;

    console.log('CALCEULATE TOUCH', {
      x: deltaX,
      y: deltaY,
      z: _calcTriangleDistance(deltaX, deltaY),
      angle: _calcAngle(deltaX, deltaY),
    });
    return {
      x: deltaX,
      y: deltaY,
      z: _calcTriangleDistance(deltaX, deltaY),
      angle: _calcAngle(deltaX, deltaY),
    };
  }
  return;
}

export function calcMoveStatus(startTouches: Finger[], touches: Finger[], time: number) {
  const { x: x1, y: y1 } = startTouches[0];
  const { x: x2, y: y2 } = touches[0];
  const deltaX = x2 - x1;
  const deltaY = y2 - y1;
  const deltaZ = _calcTriangleDistance(deltaX, deltaY);

  return {
    x: deltaX,
    y: deltaY,
    z: deltaZ,
    time,
    velocity: deltaZ / time,
    angle: _calcAngle(deltaX, deltaY),
  };
}
export function calcRotation(
  startMutliFingerStatus: MultiFingerStatus,
  mutliFingerStatus: MultiFingerStatus,
): number {
  const { angle: startAngle } = startMutliFingerStatus;
  const { angle } = mutliFingerStatus;

  return angle - startAngle;
}

export function getEventName(prefix: string, status: string): string {
  return prefix + status[0].toUpperCase() + status.slice(1);
}

export function shouldTriggerSwipe(delta: number, velocity: number): boolean {
  return Math.abs(delta) >= SWIPE.threshold && Math.abs(velocity) > SWIPE.velocity;
}

export function shouldTriggerDirection(direction: number, directionSetting: number): boolean {
  if (directionSetting & direction) {
    return true;
  }
  return false;
}

/**
 * @private
 * get the direction between two points
 * Note: will change next version
 * @param {Number} x
 * @param {Number} y
 * @return {Number} direction
 */
export function getDirection(x: number, y: number): number {
  if (x === y) {
    return DIRECTION_NONE;
  }
  if (Math.abs(x) >= Math.abs(y)) {
    return x < 0 ? DIRECTION_LEFT : DIRECTION_RIGHT;
  }
  return y < 0 ? DIRECTION_UP : DIRECTION_DOWN;
}

/**
 * @private
 * get the direction between tow points when touch moving
 * Note: will change next version
 * @param {Object} point1 coordinate point, include x & y attributes
 * @param {Object} point2 coordinate point, include x & y attributes
 * @return {Number} direction
 */
export function getMovingDirection(point1: Finger, point2: Finger): number {
  const { x: x1, y: y1 } = point1;
  const { x: x2, y: y2 } = point2;
  const deltaX = x2 - x1;
  const deltaY = y2 - y1;
  if (deltaX === 0 && deltaY === 0) {
    return DIRECTION_NONE;
  }
  if (Math.abs(deltaX) >= Math.abs(deltaY)) {
    return deltaX < 0 ? DIRECTION_LEFT : DIRECTION_RIGHT;
  }
  return deltaY < 0 ? DIRECTION_UP : DIRECTION_DOWN;
}

export function getDirectionEventName(
  direction: number,
): undefined | 'left' | 'right' | 'up' | 'down' {
  let name: undefined | 'left' | 'right' | 'up' | 'down';
  switch (direction) {
    case DIRECTION_NONE:
      break;
    case DIRECTION_LEFT:
      name = 'left';
      break;
    case DIRECTION_RIGHT:
      name = 'right';
      break;
    case DIRECTION_UP:
      name = 'up';
      break;
    case DIRECTION_DOWN:
      name = 'down';
      break;
    default:
  }
  return name;
}
