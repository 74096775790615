import React, { createContext, MutableRefObject, useRef } from 'react';
import { IAppState, PkgReducer, VevDispatch, VevReducer } from 'vev';
import { getCurrentExecute } from '../system/utils';
import { createUID } from '../utils';
import { getScrollTop, global } from '../utils/dom';
import { isFunction } from '../utils/type';
import { useFrame } from './hooks';

export const states: { [uid: string]: IAppState } = {};
export const statePendingRefs: { [uid: string]: MutableRefObject<boolean> } = {};
const reducers: VevReducer[] = [];

(window as any).vevStates = states;

export const DEFAULT_APP_STATE: IAppState = {
  project: undefined,
  root: null,
  scaling: false,
  embed: false,
  scrollTop: getScrollTop(),
  device: 'desktop',
  zoom: 1,
  viewport: {
    width: global.innerWidth,
    height: global.innerHeight,
    scrollHeight: 0,
  },
  images: {},
  shapes: {},
  models: [],
  pages: [],
  pkg: {},
  menus: {},
  primaryMenu: undefined,
  pkgStores: {},
  route: { pageKey: '' },
  settings: {
    devices: [
      {
        mode: 'desktop',
        canvasSize: [1440, 900],
        columnWidth: [1024, 1024],
      },
      {
        mode: 'tablet',
        canvasSize: [768, 1024],
        columnWidth: [600, 600],
      },
      {
        mode: 'mobile',
        canvasSize: [375, 667],
        columnWidth: [320, 320],
      },
    ],
  },
};

export function registerReducer(reducer: PkgReducer) {
  const currentPkg = getCurrentExecute()?.id;

  registerGlobalReducer((state, action, payload, pkgKey) => {
    if (pkgKey === currentPkg) {
      const currentState = pkgKey ? state.pkgStores[pkgKey] || {} : {};
      const nextState = reducer(currentState, action as string, payload);
      if (currentState !== nextState && pkgKey) {
        return { ...state, pkgStores: { ...state.pkgStores, [pkgKey]: nextState } };
      }

      return state;
    }

    return state;
  });
}

export function registerGlobalReducer(reducer: VevReducer) {
  reducers.push(reducer);
}

export function getState(uid: string): IAppState {
  return uid ? states[uid] : states[Object.keys(states)[0]];
}

export function extendAppState(state: IAppState) {
  for (const uid in states) {
    const s = states[uid];
    if (s.project === state.project) {
      Object.assign(s, {
        models: joinList(s.models, state.models),
        images: { ...s.images, ...state.images },
        shapes: { ...s.shapes, ...state.shapes },
        pkg: { ...s.pkg, ...state.pkg },
      });

      statePendingRefs[uid].current = true;
    }
  }
}

type StateProviderProps = {
  state?: Partial<IAppState>;
  children: React.ReactNode;
};

type StateListener = (state: IAppState) => void;
type ContextState = [(listener: StateListener) => () => void, VevDispatch, string];

export const StateContext = createContext<ContextState>([() => () => {}, () => {}, '']);

export function StateProvider({ state: initState, children }: StateProviderProps) {
  const contextState = useRef<ContextState>();
  const listeners = useRef<StateListener[]>([]);
  const pending = useRef<boolean>(false);
  useFrame(() => {
    if (pending.current) {
      pending.current = false;
      for (const listener of listeners.current) {
        const stateKey = contextState.current?.[2];
        if (stateKey) {
          listener(states[stateKey]);
        }
      }
    }
  }, []);

  if (!contextState.current) {
    const uid = createUID();
    listeners.current = [oldNotify];
    states[uid] = { ...DEFAULT_APP_STATE, ...initState };
    statePendingRefs[uid] = pending;

    contextState.current = [
      (listener) => {
        const index = listeners.current.indexOf(listener);
        if (index === -1) listeners.current.push(listener);
        listener(states[uid]);

        return () => {
          const index = listeners.current.indexOf(listener);
          if (index !== -1) listeners.current.splice(index, 1);
        };
      },
      (action, payload, pkgKey) => {
        if (typeof action === 'object') {
          states[uid] = { ...states[uid], ...(action as any) };
          pending.current = true;
        } else {
          for (const reducer of reducers) {
            const currentState = states[uid];
            const nextState = reducer(currentState, action, payload, pkgKey);

            if (nextState && currentState !== nextState) {
              states[uid] = nextState;
              pending.current = true;
            }
          }
        }
      },
      uid,
    ];
  }

  return <StateContext.Provider value={contextState.current}>{children}</StateContext.Provider>;
}

const oldListeners: StateListener[] = [];

function oldNotify(s: IAppState) {
  for (const l of oldListeners) l(s);
}

export function store(attr: keyof IAppState, cb: (state: any) => void) {
  console.warn('The store function is deprecated');

  if (cb && isFunction(cb)) {
    let stateId = Object.keys(states)[0];
    let prev = stateId && states[stateId][attr];
    cb(prev);
    const func: StateListener = (s) => {
      if (!stateId) stateId = Object.keys(states)[0];
      const next = s[attr];
      if (next !== prev) {
        prev = next;
        cb(next);
      }
    };
    oldListeners.push(func);
    return () => {
      const index = oldListeners.indexOf(func);
      oldListeners.splice(index, 1);
    };
  }
}

function joinList<T extends { key: string }>(l1: T[], l2: T[]): T[] {
  return [
    ...l1,
    ...l2.filter((item) => {
      for (const m of l1) {
        if (m.key === item.key) return false;
      }
      return true;
    }),
  ];
}
